import React from "react";

export function Muster() {
  return (
    <a
      className="underline"
      href="https://www.muster.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`muster-icon.png`}
        alt="Muster logo"
        style={{ display: "inline", height: "20px" }}
      />
      Muster
    </a>
  );
}
