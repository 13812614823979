// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon
import locationData from "./locations.json";

const locationCodesWithImage = [
  // "al",
  // "ak",
  "az",
  // "ar",
  // "ca",
  // "co",
  // "ct",
  // "de",
  "fl",
  // "ga",
  // "hi",
  // "id",
  // "il",
  // "in",
  // "ia",
  "ks",
  // "ky",
  // "la",
  // "me",
  "md",
  // "ma",
  // "mi",
  // "mn",
  // "ms",
  "mo",
  // "mt",
  // "ne",
  // "nv",
  // "nh",
  // "nj",
  // "nm",
  "ny",
  // "nc",
  // "nd",
  // "oh",
  // "ok",
  // "or",
  // "pa",
  "ri",
  // "sc",
  // "sd",
  // "tn",
  // "tx",
  // "ut",
  // "vt",
  "va",
  "wa",
  "wv",
  // "wi",
  // "wy",
];

export interface Location {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const locations: Location[] = locationData;

export const locationsWithImage = locations.filter((c) =>
  locationCodesWithImage.includes(c.code.toLowerCase())
);

export function getGoogleMapsUrl(country: Location): string {
  return `https://www.google.com/maps/@${country.latitude},${country.longitude},14z`;
}

export function sanitizeLocationName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
