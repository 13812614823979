import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import seedrandom from "seedrandom";
import { locationsWithImage, Location } from "../domain/locations";
import { Guess, loadAllGuesses, saveGuesses } from "../domain/guess";

const forcedLocations: Record<string, string> = {
  "2022-03-30": "VA",
  "2022-03-31": "AZ",
  "2022-04-01": "MD",
  "2022-04-02": "FL",
  "2022-04-03": "RI",
  "2022-04-04": "KS",
  "2022-04-05": "MO",
};

export function getDayString(shiftDayCount?: number) {
  return DateTime.now()
    .plus({ days: shiftDayCount ?? 0 })
    .toFormat("yyyy-MM-dd");
}

export function useTodays(dayString: string): [
  {
    country?: Location;
    guesses: Guess[];
  },
  (guess: Guess) => void,
  number,
  number
] {
  const [todays, setTodays] = useState<{
    country?: Location;
    guesses: Guess[];
  }>({ guesses: [] });

  const addGuess = useCallback(
    (newGuess: Guess) => {
      if (todays == null) {
        return;
      }

      const newGuesses = [...todays.guesses, newGuess];

      setTodays((prev) => ({ country: prev.country, guesses: newGuesses }));
      saveGuesses(dayString, newGuesses);
    },
    [dayString, todays]
  );

  useEffect(() => {
    const guesses = loadAllGuesses()[dayString] ?? [];
    const country = getLocation(dayString);

    setTodays({ country, guesses });
  }, [dayString]);

  const randomAngle = useMemo(
    () => seedrandom.alea(dayString)() * 360,
    [dayString]
  );

  const imageScale = useMemo(() => {
    const normalizedAngle = 45 - (randomAngle % 90);
    const radianAngle = (normalizedAngle * Math.PI) / 180;
    return 1 / (Math.cos(radianAngle) * Math.sqrt(2));
  }, [randomAngle]);

  return [todays, addGuess, randomAngle, imageScale];
}

function getLocation(dayString: string) {
  const forcedLocationCode = forcedLocations[dayString];
  const forcedLocation =
    forcedLocationCode != null
      ? locationsWithImage.find(
          (country) => country.code === forcedLocationCode
        )
      : undefined;

  return (
    forcedLocation ??
    locationsWithImage[
      Math.floor(seedrandom.alea(dayString)() * locationsWithImage.length)
    ]
  );
}
